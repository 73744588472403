import React from "react";
import Layout from "../../components/Layout";
import { Container, Heading } from "@chakra-ui/react";
import PageHeadingSection from "../../components/PageHeadingSection";

export default function ExemplarGlobal() {
  return (
    <Layout>
      <PageHeadingSection title={"Exemplar Global Calendar"} />
      <Container maxW={"7xl"} py={5}>
        <Heading
          as="h2"
          size="md"
          textAlign={"center"}
          my={5}
          fontWeight={"bold"}
        >
          For Countries: Spain, South Africa, North America, Australia
        </Heading>
        <iframe
          src="/training-calendars/EG_Training_Calendar_1.pdf"
          width="90%"
          height="900"
          title="CQI-IRCA Calendar"
          style={{ margin: "auto" }}
        ></iframe>
        <Heading
          as="h2"
          size="md"
          textAlign={"center"}
          my={5}
          fontWeight={"bold"}
        >
          For Countries: US, Canada, Argentina, Nigeria, Mexico
        </Heading>
        <iframe
          src="/training-calendars/EG_Training_Calendar_2.pdf"
          width="90%"
          height="900"
          title="CQI-IRCA Calendar"
          style={{ margin: "auto" }}
        ></iframe>
        <Heading
          as="h2"
          size="md"
          textAlign={"center"}
          my={5}
          fontWeight={"bold"}
        >
          For Country: India
        </Heading>
        <iframe
          src="/training-calendars/EG_Training_Calendar_3.pdf"
          width="90%"
          height="900"
          title="CQI-IRCA Calendar"
          style={{ margin: "auto" }}
        ></iframe>
      </Container>
    </Layout>
  );
}
